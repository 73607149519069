import { FormModel_scopeItems_FE, FormModel_scopeStores_FE } from '@prisma/containers/model/scope.models';
import { PromotionBlock, PromotionCombo, PromotionComboDTO } from '../combos-components-v2/models/combosV2.model';

//DES-4462 > Traducciones: PROMOV2.ACTIONS_STATUS.
export enum ActionsV2_StatusEnum {
  New = 'New',
  Modified = 'Modified', // > no edita, Permite volver a publicar
  Current = 'Current', // > no edita
  Endorsed = 'Endorsed',
  //
  Cancelled = 'Cancelled',
  Informed = 'Informed',
  Deleted = 'Deleted',
}

export enum ActionsV2_ValidationStatus {
  //DES-4462  > Traducciones: PROMOV2.ACTIONS_VALIDATION_STATUS
  Approved = 'Approved',
  Validating = 'Validating',
  ActionRequired = 'ActionRequired',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export enum ActionsV2_PublishStatus {
  None = 'None',
  Processing = 'Processing',
  Error = 'Error',
}

export interface ActionsV2_validationObject {
  actionId: string | number;
  validationStatus: ActionsV2_ValidationStatus;
  validations?: ActionsV2_validationItem[];
}

export interface ActionsV2_validationItem {
  validationId: number;
  roleId?: number;
  validationName: string;
  validationDescription: string;
  validationFailedMessage: string;
  validationSuccessMessage: string;
  failedCount: number;
  //
  status: ValidationStatus;
  forcedApproval?: any;
  isBlocker: boolean;
  validationType?: validationWarningTypes;
  minimumCoverage?: number;
}
export enum ValidationStatus {
  passed = 'Passed',
  failed = 'Failed',
  incomplete = 'Incomplete',
}

export interface ActionsV2_FormsStateModel {
  form: ActionsV2_FormModel;
  formOriginal: ActionsV2_FormModel;
  coverage?: any;
  _isFetchingStatus: boolean;
}

export interface ResponseMessages {
  type: string;
  keyword: string;
  values: string;
  link: string;
}

export interface ActionsV2_FormModel {
  mainData: ActionsV2_FormModel_mainData;
  activationData: ActionsV2_FormModel_activationData;
  itemScope: FormModel_scopeItems_FE;
  storeScope: FormModel_scopeStores_FE;
  promotionCombos: FormModel_promotionCombos_FE[] | PromotionComboDTO[];
  promotionCombosV2?: PromotionCombo[];
  giftItemScope: ActionsV2_FormModel_scopeItemsGifts_FE;
  _payments: ActionsV2_FormModel_payments;
  discountRanges: ActionsV2_FormModel_discountRanges[];
  publishEnable: boolean;
  _inSave?: boolean;
  _publishOk?: boolean;
  _createdEntity?: boolean;
  _responseMessages?: ResponseMessages[];
  _responseSuccess?: boolean;
  customFields?: any[];
  _tagId?: any;
  _completeCoverage?: boolean;
  communicationType?: ActionsV2__communicationType; //4589
  communicationDisplay?: ActionsV2__communicationDisplay; //4763
  storeScopeSelected?: StoreScopeSelected;
  actionScopeType: ActionScopeType; //PRO-197
  itemIds?: number[];
  storeIds?: number[];
  fixedPriceDiscountData?: FixedPriceDiscount;
  fixedPriceDiscountCombos?: FixedPriceDiscountCombos[];
  discountDataLoading?: boolean;
}

export interface StoreScopeSelected {
  itemScopes: [
    {
      categoryIdsToInclude: string[] | number[];
      categoryIdsToExclude: string[] | number[];
      tagValueIdsToInclude: string[] | number[];
      tagValueIdsToExclude: string[] | number[];
      itemIds: number[];
      itemIdsToExclude: string[] | number[];
      onlyItemsAllowedForSelling: boolean;
    }
  ];
  tagValueIdsToInclude: string[] | number[];
  tagValueIdsToExclude: string[] | number[];
  storeIds: string[] | number[];
  storeIdsToExclude: string[] | number[];
}

//4589
export enum ActionsV2__communicationType {
  CommunicateDiscount = 'CommunicateDiscount',
  CommunicatePrice = 'CommunicatePrice',
}

export enum ActionsV2__paymentSelectionType {
  None = 'None',
  PaymentMethods = 'PaymentMethods',
  PaymentMethodCombinations = 'PaymentMethodCombinations',
}

//4763
export enum ActionsV2__communicationDisplay {
  CommunicateFirstValue = 'CommunicateFirstValue',
  CommunicateFirstName = 'CommunicateFirstName',
}

export interface ActionsV2_FormModel_payments {
  _paymentSelection?: ActionsV2__paymentSelectionType;
  paymentMethods: ActionsV2_FormModel_paymentMethods[];
  paymentMethodCombinations: ActionsV2_FormModel_paymentMethodsCombinations[];
}

export interface ActionsV2_FormModel_scopeItemsGifts_FE extends FormModel_scopeItems_FE {
  size: number;
}

export interface ActionsV2_FormModel_mainData {
  id: number;
  status?: ActionsV2_StatusEnum;
  publishStatus?: ActionsV2_PublishStatus;
  validationStatus?: ActionsV2_ValidationStatus; //DES-4462
  name: string;
  code: string;
  externalCode: string;
  type: ActionsV2_Types;
  message: string;
  observation: string;
  validFrom: string; //'0001-01-01T00:00:00';
  validTo: string; //'0001-01-01T00:00:00';
  frequency: ActionsV2_FrequencyType;
  frequencyDays: number[];
  accumulatesWithOtherActions: boolean;
  _selectTimeRangeAllDay?: boolean;
  timeRange?: {
    timeFrom: string; //'15:00:00';
    timeTo: string; //'15:00:00';
    _timeFromInvalid?: boolean;
    _timeToInvalid?: boolean;
  }[];
  campaignId: number;
  //
  promotionTypeId?: any; //NEW OXXO
  promotionPeriodId?: any; //NEW OXXO
  promotionYear: number; //NEW OXXO //DES-4649
  isSuggested?: boolean; //DES-4759
  estimatedSales?: number;
}

export interface ActionsV2_FormModel_activationData {
  assortmentMethod: ActionsV2_AssortmentMethodType;
  salesChannelCodes?: string[];
  loyaltyProgramCodes?: string[];
  minimumQuantity: number | string;
  payUnits: number | string;
  sameItem: boolean;
  minimumValue: number | string;
  minimumTotalValue: number | string;
  discountType: ActionsV2_DiscountType;
  discount: number;
  fixPrice: number;
  shippingDiscount?: number;
  maximumDiscountValue: number;
  untilStockLast: boolean; //TODO: Agregar
  loyaltyProgram: ActionsV2_FormModel_activationData_LoyaltyProgram[];
  discountFixedPrice?: any;
  discountLoyalty?: any;
  discountExceptionalPrice?: any;
  communicationBasedOnAssortment: boolean;
  communicateRegularPrice: boolean;
  communicationType?: any;
  communicationDisplay?: any;
  promotionalExampleMessage?: string;
  supportTextId?: number;
  promotionalMessageLoading?: boolean;
  planogramPopMaterialIds?: any[]; //NEW OXXO
}

export interface ActionsV2_FormModel_activationData_LoyaltyProgram {
  id?: number;
  loyaltyProgramId: number;
  value: number;
}

export interface ActionsV2_FormModel_discountRanges {
  valueRange: boolean;
  from: number;
  to: number;
  discount: any;
  fixPrice: any;
  discountType: ActionsV2_DiscountType;
}

export interface ActionsV2_FormModel_paymentMethods {
  paymentMethodId: number;
  paymentMethodName: string;
  bankId: number;
  bankName: string;
  sponsorship: number; //Porcentaje > solo para bancos
  installments: { number: number; interestRate: number }[]; //Cuotas
  loyaltyProgramPoints: number; // es para LoyaltyPoints type // inidca canteidad de puntos para pagar //opcional
  loyaltyProgramPointsEquivalenceInCash: number; // es para LoyaltyPoints type // equivalencia entre puntos y efectivo, //opcional
}

export interface ActionsV2_FormModel_paymentMethod {
  id: number;
  code: string;
  name: string;
  isCredit: boolean;
  isDebit: boolean;
  type: ActionsV2_FormModel_actionPaymentMethodTypeEnum;
}

export interface ActionsV2_FormModel_bank {
  id: number;
  code: string;
  name: string;
}

export interface ActionsV2_FormModel_paymentMethodsCombinations {
  actionPaymentMethodCombination: ActionsV2_FormModel_actionPaymentMethodCombination[];
}

export interface ActionsV2_FormModel_actionPaymentMethodCombination {
  type: ActionsV2_FormModel_actionPaymentMethodTypeEnum;
  paymentMethodId: number;
  value: number;
}

export enum ActionsV2_FormModel_actionPaymentMethodTypeEnum {
  Money = 'Money',
  LoyaltyPoints = 'LoyaltyPoints',
}

export interface ActionsV2TabsComponentValues {
  mainData?: any;
  activationData?: any;
  itemScope?: any;
  storeScope?: any;
  giftItemScope?: any;
  customFields?: any;
  [key: string]: boolean;
}
export enum CustomFieldType {
  Number = 1,
  Text = 2,
  DateTime = 3,
  Boolean = 4,
  List = 5,
}
export interface CustomFiledsDto {
  groupName: string;
  name: string;
  type: number;
  regex?: string;
  validationHint?: any;
  isMandatory?: boolean;
  requireSpecialPermission?: boolean;
  listValues: string;
}
export interface CustomFiledsToBe {
  groupName: string;
  name: string;
  type: string;
  value: string;
}
export enum ActionsV2_Types {
  Discount = 'Discount',
  BuyAPayB = 'BuyAPayB',
  BuyAGetDiscountInXUnit = 'BuyAGetDiscountInXUnit',
  Surcharge = 'Surcharge',
  FixPrice = 'FixPrice',
  Gift = 'Gift',
  Wholesaler = 'Wholesaler',
  ImageCommunication = 'ImageCommunication',
}

export enum ActionsV2_FrequencyType {
  EveryDay = 'EveryDay', //La promocion está activa todos los dias
  CertainDaysOfTheWeek = 'CertainDaysOfTheWeek', //La promocion solo esta activa ciertos dias de la semana
  CertainDaysOfTheMonth = 'CertainDaysOfTheMonth', //La promocion solo esta activa ciertos dias del mes
}

export enum ActionsV2_AssortmentMethodType {
  ActivateOnlyDuringPromotion = 'ActivateOnlyDuringPromotion', //Activar los productos durante la vigencia de la promoción.
  ActivateIndefinitely = 'ActivateIndefinitely', //Activar los productos indefinidamente
  DoNotActivate = 'DoNotActivate', //No activar los productos.
}

export enum ActionsV2_DiscountType {
  AbsoluteValue = 'AbsoluteValue',
  Percentage = 'Percentage',
}

export enum AttributeFilterTypesPromotionV2 {
  ManualSelection = 1,
  FilterSelection = 2,
}
export enum validationWarningTypes {
  Coverage = 'Coverage',
  MaxSlots = 'MaxSlots',
  FixAndRegularPrice = 'FixAndRegularPrice',
  storesAndPromotionalSpace = 'storesAndPromotionalSpace',
  referencePrice = 'ReferencePrice',
}

export interface FormModel_promotionCombos_FE {
  combo: PromotionBlock[];
  id: string | number;
  price: number;
}

export interface FixedPriceDiscountCombos {
  comboId: number;
  result: FixedPriceDiscount;
}
export interface FixedPriceDiscount {
  discount?: number;
  loyaltyDiscount?: number;
  regularPrice?: number;
  loyaltyRegularPrice?: number;
  exceptionalDiscounts?: ExceptionalDiscounts[];
}
export interface ExceptionalDiscounts {
  storeId: number;
  tagId?: number;
  discount: number;
  regularPrice: number;
}
export enum ActionScopeType {
  Mixed = 'Mixed',
  Staggered = 'Staggered',
  Simple = 'Simple',
}

export interface SupportTextItem {
  id: number;
  text: string;
}
